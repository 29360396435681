import { BaseClass } from './base'
import defaultConfig from '@/config/default.config'

export class RcClass extends BaseClass {
  project = defaultConfig.rc_host + '/api/Project'
  resource = defaultConfig.rc_host + '/api/Resource'

  createDir (data) {
    return this.post(this.project, data)
  }

  getDirs (params) {
    return this.get(this.project, params)
  }

  add (data) {
    return this.upload(this.resource, data)
  }

  gets (params) {
    return this.get(this.resource, params)
  }
}
