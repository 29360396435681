import { BaseClass } from './base'

export class MenuClass extends BaseClass {
  modelRef = {
    name: '',
    sign: '',
    roleId: '',
    remark: '',
    sort: 0
  }

  rulesRef = {
    name: [
      {
        required: true,
        message: '请输入标题'
      }
    ],
    sign: [
      {
        required: true,
        message: '请输入标识'
      }
    ],
    roleId: [
      {
        required: true,
        message: '请选择分类'
      }
    ]
  }

  gets (params) {
    return this.get(this.menu, params)
  }

  create (data) {
    return this.post(this.menu, data)
  }

  update (data) {
    return this.put(this.menu, data)
  }

  del (id) {
    return this.delete(this.menu + `/${id}`)
  }

  sort (id, sort) {
    return this.get(this.menu + `/${id}/${sort}`)
  }
}
