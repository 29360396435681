import { BaseClass } from './base'

export class RoleClass extends BaseClass {
  modelRef = {
    name: ''
  }

  rulesRef = {
    name: [
      {
        required: true,
        message: '请输入角色名称'
      }
    ]
  }

  gets (params) {
    return this.get(this.role, params)
  }

  create (data) {
    return this.post(this.role, data)
  }

  update (data) {
    return this.put(this.role, data)
  }

  del (id) {
    return this.delete(this.role + `/${id}`)
  }

  menus (id, data) {
    return this.put(this.role + `/${id}`, data)
  }

  all () {
    return this.get(this.role + '/GetAllRoles')
  }
}
