import { MenuClass } from '@/apis/menu'
import { RoleClass } from '@/apis/role'
import { StoreClass } from '@/apis/store'
import { GoodsCategoryClass } from '@/apis/goods'
import { ComboClass } from '@/apis/combo'
import { WxGZHCLass } from '@/apis/wxgzh'
import { SupplierClass } from '@/apis/supplier'
import { DishTypeClass, DishClass, ShoppingCartClass } from '@/apis/dish'
import { PlanClass } from '@/apis/plan'
import { UserClass } from '@/apis/user'
import { TableClass } from '@/apis/table'

const role = new RoleClass()
const menu = new MenuClass()
const store = new StoreClass()
const category = new GoodsCategoryClass()
const combo = new ComboClass()
const wxgzh = new WxGZHCLass()
const supplier = new SupplierClass()
const dishType = new DishTypeClass()
const dish = new DishClass()
const plan = new PlanClass()
const user = new UserClass()
const table = new TableClass()
const shopcart = new ShoppingCartClass()
const data = {
  state: {
    menus: [],
    ALL_RULES: [],
    ALL_ROLES: [],
    ALL_STORES: [],
    ALL_GOODSCATEGORYS: [],
    ALL_COMBOS: [],
    ALL_SUPPLIER: [],
    ALL_DISHTYPES: [],
    ALL_PLAN: [],
    ALL_USER: [],
    ALL_TABLE: [],
    ALL_TEMPDISH: [],
    ALL_HANDLERS: [],
    ALL_MANAGES: []
  },
  mutations: {
    SET_DATA_MENUS (state, value) {
      state.menus = value
    },

    SET_DATA_ROLES (state, value) {
      state.ALL_ROLES = value
    },

    SET_DATA_RULES (state, value) {
      state.ALL_RULES = value
    },

    SET_DATA_STORES (state, value) {
      state.ALL_STORES = value
    },

    SET_DATA_GOODSCATEGORYS (state, value) {
      state.ALL_GOODSCATEGORYS = value
    },

    SET_DATA_DISHTYPES (state, value) {
      state.ALL_DISHTYPES = value
    },

    SET_DATA_COMBOS (state, value) {
      state.ALL_COMBOS = value
    },

    SET_DATA_SUPPLIER (state, value) {
      state.ALL_SUPPLIER = value
    },

    SET_DATA_PLAN (state, value) {
      state.ALL_PLAN = value
    },

    SET_DATA_USER (state, value) {
      state.ALL_USER = value
    },

    SET_DATA_HANDLERS (state, value) {
      state.ALL_HANDLERS = value
    },

    SET_DATA_TABLE (state, value) {
      state.ALL_TABLE = value
    },

    SET_DATA_TEMPDISH (state, value) {
      state.ALL_TEMPDISH = value
    },

    SET_DATA_SHOP_CART_DISHS (state, value) {
      state.SHOP_CART_DISHS = value
    },

    SET_DATA_ALL_MANAGES (state, value) {
      state.ALL_MANAGES = value
    }
  },
  actions: {
    // 权限菜单
    GetMenuData ({ commit }, roleId) {
      return new Promise((resolve, reject) => {
        menu.gets({ roleId, Limit: 99999 }).then(resp => {
          commit('SET_DATA_MENUS', resp.data)
          resolve(resp.data)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    // 全部角色
    GetAllRoles ({ commit }) {
      return new Promise((resolve, reject) => {
        role.all().then(resp => {
          commit('SET_DATA_ROLES', resp)
          resolve(resp)
        }).catch(() => {
          reject(new Error('获取角色失败 !'))
        })
      })
    },

    // 全部平台用户
    GetAllUsers ({ commit }) {
      return new Promise((resolve, reject) => {
        user.platformUsers().then(resp => {
          commit('SET_DATA_USER', resp)
          resolve(resp)
        }).catch(() => {
          reject(new Error('获取平台用户 !'))
        })
      })
    },

    // 全部客户经理
    GetAllHandlers ({ commit }) {
      return new Promise((resolve, reject) => {
        user.handlersUsers().then(resp => {
          commit('SET_DATA_HANDLERS', resp)
          resolve(resp)
        }).catch(() => {
          reject(new Error('获取客户经理 !'))
        })
      })
    },

    // 全部权限
    GetAllRules ({ commit }) {
      return new Promise((resolve, reject) => {
        menu.gets({ Limit: 99999 }).then(resp => {
          commit('SET_DATA_RULES', resp.data)
          resolve(resp.data)
        }).catch(() => {
          reject(new Error('获取权限失败 !'))
        })
      })
    },

    // 全部酒店
    GetAllStores ({ commit }) {
      return new Promise((resolve, reject) => {
        store.gets().then(resp => {
          commit('SET_DATA_STORES', resp.data)
          resolve(resp.data)
        }).catch(() => {
          reject(new Error('获取酒店失败 !'))
        })
      })
    },

    // 全部商品分类
    GetAllGoodsCategorys ({ commit }) {
      return new Promise((resolve, reject) => {
        category.gets().then(resp => {
          commit('SET_DATA_GOODSCATEGORYS', resp)
          resolve(resp)
        }).catch(() => {
          reject(new Error('获取商品分类失败 !'))
        })
      })
    },

    // 全部菜品分类
    GetAllDishTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        dishType.gets().then(resp => {
          commit('SET_DATA_DISHTYPES', resp)
          resolve(resp)
        }).catch(() => {
          reject(new Error('获取菜品分类失败 !'))
        })
      })
    },

    // 全部套餐
    GetAllCombos ({ commit }) {
      return new Promise((resolve, reject) => {
        combo.gets({ Limit: 9999 }).then(resp => {
          commit('SET_DATA_COMBOS', resp.data)
          resolve(resp.data)
        }).catch(() => {
          reject(new Error('获取套餐失败 !'))
        })
      })
    },

    WxConfig ({ commit }, url) {
      return new Promise((resolve, reject) => {
        wxgzh.jsConfig(url).then(resp => {
          resolve(resp)
        }).catch(() => {
          reject(new Error('获取微信配置失败 !'))
        })
      })
    },

    // 全部供货商
    GetAllSuppliers ({ commit }) {
      return new Promise((resolve, reject) => {
        supplier.gets({ Limit: 9999 }).then(resp => {
          commit('SET_DATA_SUPPLIER', resp.data)
          resolve(resp.data)
        }).catch(() => {
          reject(new Error('获取供货商失败 !'))
        })
      })
    },

    // 全部采购计划
    GetAllPlans ({ commit }) {
      return new Promise((resolve, reject) => {
        plan.gets({ Limit: 9999 }).then(resp => {
          commit('SET_DATA_PLAN', resp.data)
          resolve(resp.data)
        }).catch(() => {
          reject(new Error('获取采购计划失败 !'))
        })
      })
    },

    // 全部台位
    GetAllTable ({ commit }) {
      return new Promise((resolve, reject) => {
        table.gets({ Limit: 9999 }).then(resp => {
          commit('SET_DATA_TABLE', resp.data)
          resolve(resp.data)
        }).catch(() => {
          reject(new Error('获取台位失败 !'))
        })
      })
    },

    // 全部临时菜
    GetAllTempDishs ({ commit }) {
      return new Promise((resolve, reject) => {
        dish.tempDishes().then(resp => {
          commit('SET_DATA_TEMPDISH', resp)
          resolve(resp)
        }).catch(() => {
          reject(new Error('获取临时菜失败 !'))
        })
      })
    },

    // 获取购物车菜品
    GetShopCarGoods ({ commit }) {
      return new Promise((resolve, reject) => {
        shopcart.gets().then(resp => {
          commit('SET_DATA_SHOP_CART_DISHS', resp)
          resolve(resp)
        }).catch(() => {
          reject(new Error('获取购物车菜品失败 !'))
        })
      })
    },

    // 获取所有客户经理及当前用户（吧台）
    GetManagersAndCurrUser ({ commit }) {
      return new Promise((resolve, reject) => {
        user.getManagersAndCurrUser().then(resp => {
          commit('SET_DATA_ALL_MANAGES', resp)
          resolve(resp)
        }).catch(() => {
          reject(new Error('获取所有客户经理及当前用户（吧台） !'))
        })
      })
    }
  }
}

export default data
