<template>
  <a-layout style="height: 100vh">
    <a-layout-sider v-model:collapsed="collapsed" collapsible :trigger="null">
      <h-menu />
      <div class="version">版本：{{version}}</div>
    </a-layout-sider>
    <a-layout>
      <a-layout-content style="margin: 0 16px">
        <div class="router-body">
          <div class="router-header">
            <h-header @collapsed="e => collapsed = e" />
          </div>
          <div class="router-content">
            <router-view></router-view>
          </div>
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
import { defineComponent, ref } from 'vue'
import store from '@/store'
import HMenu from '@/components/Menu.vue'
import HHeader from '@/components/Header.vue'

export default defineComponent({
  setup () {
    const collapsed = ref(false)
    const selectedKeys = ref(['1'])
    const version = store.state.app.version
    return {
      collapsed,
      selectedKeys,
      version
    }
  },
  components: {
    HMenu,
    HHeader
  },

  created () {
    document.documentElement.style.fontSize = document.documentElement.clientWidth / 19.2 + 'px'
  }
})
</script>
<style scoped>
#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(235, 230, 230, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}
[data-theme="dark"] .site-layout .site-layout-background {
  background: #141414;
}

.router-body{
  height: 100%;
  width: 100%;
  padding: 68px 0 0;
  box-sizing: border-box;
  position: relative;
}
.router-header{
  width: 100%;
  height: 50px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 9px;
  left: 0;
  border-radius: 10px;
}
.router-content{
  width: 100%;
  height: 100%;
  position: relative;
}
.version{
  position: relative;
  top: -20px;
  color: #fff;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  color: #f1f1f1;
}
</style>
