<template>
  <div>
    <div class="top-menus">
      <router-link v-for="(v,k) in menus" :key="k" :to="v.path">{{v.meta.title}}</router-link>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import store from '@/store'

export default defineComponent({
  setup () {
    return {}
  },

  computed: {
    menus () {
      const list = store.state.permission.addRouters[0].children
      const list2 = list.filter(x => x.path === '/counter/stat')[0]
      return list2.children
    }
  }
})
</script>

<style lang="less" scoped>
.top-menus{
  position: absolute;
  top: -50px;
  left: 0;
  height: 50px;
  display: flex;
  align-items: center;
  z-index: 9;
  a{
    display: block;
    color: #333;
    padding: 5px 10px;
    margin-right: 15px;
    border: 1px solid #333;
    border-radius: 5px;

    &.router-link-active{
      color: #998675;
      border-color: #998675;
    }
  }
}
</style>
