<template>
  <e-modal
    :visible="visible"
    title="裁剪图片"
    :width="850"
    :closeAble="false"
    @close="onClose"
    :footer="false"
  >
    <div class="cropper-body">
      <div class="cropper">
        <div class="cropper-box">
          <vue-cropper
            ref="cropper"
            :containerStyle="{
              width: '500px',
              height: '500px'
            }"
            v-if="imgSrc"
            :src="imgSrc"
            dragMode="move"
            :viewMode="1"
            :preview="$refs.previewBox"
            alt="Source Image"
            @ready="onReady"
            @cropmove="onCrop"
            @cropend="onCropEnd"
          ></vue-cropper>
        </div>

        <div style="margin-top: 15px">
          <a-upload
            name="file"
            :beforeUpload="beforeUpload"
            :showUploadList="false"
          >
            <a-button type="primary"> <PictureOutlined /> 选择图片 </a-button>
          </a-upload>
          <a-button
            style="margin-left: 15px"
            @click="onReset"
            :disabled="imgSrc === ''"
          >
            <ReloadOutlined /> 重置图片
          </a-button>
        </div>
      </div>
      <div class="preview">
        <div class="preview-body">
          <div class="preview-box" ref="previewBox"></div>
        </div>

        <div class="actions">
          <a-row type="flex" align="middle" style="margin-top: 15px">
            <a-col :span="4">旋转</a-col>
            <a-col>
              <a-input-number
                v-model:value="action.rotate"
                @change="onRotateChange"
                :disabled="imgSrc === ''"
              />
            </a-col>
            <a-col :offset="1">
              <a-button @click.stop="onRotate(-1)" :disabled="imgSrc === ''">
                <RotateLeftOutlined />
              </a-button>
              <a-button @click.stop="onRotate(1)" :disabled="imgSrc === ''">
                <RotateRightOutlined />
              </a-button>
            </a-col>
          </a-row>

          <a-row type="flex" align="middle" style="margin-top: 15px">
            <a-col :span="4">缩放</a-col>
            <a-col>
              <a-input-number
                v-model:value="action.zoom"
                @change="onZoomChange"
                :step="0.1"
                :disabled="imgSrc === ''"
              />
            </a-col>
            <a-col :offset="1">
              <a-button @click.stop="onZoom(-0.1)" :disabled="imgSrc === ''">
                <ZoomOutOutlined />
              </a-button>
              <a-button @click.stop="onZoom(0.1)" :disabled="imgSrc === ''">
                <ZoomInOutlined />
              </a-button>
            </a-col>
          </a-row>

          <a-row type="flex" align="middle" style="margin-top: 15px">
            <a-col :span="4">尺寸</a-col>
            <a-col>
              <a-input-number
                v-model:value="action.width"
                @change="onWidthChange"
                :step="1"
                :disabled="imgSrc === ''"
              />
            </a-col>
            <a-col>
              <CloseOutlined style="font-size: 12px" />
            </a-col>
            <a-col>
              <a-input-number
                v-model:value="action.height"
                @change="onHeightChange"
                :step="1"
                :disabled="imgSrc === ''"
              />
            </a-col>
          </a-row>

          <div class="move">
            <a-row>
              <a-col :span="4">移动</a-col>
              <a-col :span="20">
                <div class="hang">
                  <a-button @click="onMove(0,-1)"><ArrowUpOutlined /></a-button>
                </div>
                <div class="hang">
                  <a-button @click="onMove(-1,0)"><ArrowLeftOutlined /></a-button>
                  <a-button @click="onMove(0,1)"><ArrowDownOutlined /></a-button>
                  <a-button @click="onMove(1,0)"><ArrowRightOutlined /></a-button>
                </div>
              </a-col>
            </a-row>
          </div>

          <div class="ac">
            <a-button
              type="primary"
              @click="onSubmit"
              :disabled="imgSrc === ''"
              :loading="loading"
            >
              上传
            </a-button>

            <a-button @click="onClose" style="margin-left: 15px">关闭</a-button>
          </div>
        </div>
      </div>
    </div>
  </e-modal>
</template>

<script>
import { defineComponent, ref } from 'vue'
import {
  ReloadOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  ZoomOutOutlined,
  ZoomInOutlined,
  CloseOutlined,
  PictureOutlined,
  ArrowUpOutlined,
  ArrowLeftOutlined,
  ArrowDownOutlined,
  ArrowRightOutlined
} from '@ant-design/icons-vue'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import storage from 'store'

export default defineComponent({
  components: {
    VueCropper,
    ReloadOutlined,
    RotateLeftOutlined,
    RotateRightOutlined,
    ZoomOutOutlined,
    ZoomInOutlined,
    CloseOutlined,
    PictureOutlined,
    ArrowUpOutlined,
    ArrowLeftOutlined,
    ArrowDownOutlined,
    ArrowRightOutlined
  },
  setup (props, { emit }) {
    const action = ref({
      rotate: 0,
      zoom: 1,
      width: 400,
      height: 400
    })
    const imgSrc = ref('')
    const imgFile = ref({})
    const visible = ref(false)
    const loading = ref(true)

    const beforeUpload = file => {
      imgFile.value = file
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        imgSrc.value = reader.result
      }
      return false
    }

    return {
      imgSrc,
      visible,
      imgFile,
      action,
      loading,

      beforeUpload
    }
  },

  methods: {
    open () {
      this.loading = true
      this.visible = true
    },

    onSubmit () {
      this.loading = true
      this.$refs.cropper.getCroppedCanvas().toBlob(blob => {
        const formData = new FormData()
        formData.append('file', blob, this.imgFile.name)
        this.$emit('ok', formData)
      })

      storage.set('CROPPER_WIDTH', this.action.width)
      storage.set('CROPPER_HEIGHT', this.action.height)
    },

    onClose () {
      this.imgSrc = ''
      if (this.$refs.cropper) {
        this.$refs.cropper.destroy()
      }

      this.visible = false
    },

    onReady () {
      const width = storage.get('CROPPER_WIDTH') || this.action.width
      const height = storage.get('CROPPER_HEIGHT') || this.action.height
      const data = {
        width,
        height,
        top: 50,
        left: 50
      }
      this.$refs.cropper.setCropBoxData(data)

      this.loading = false
    },

    onCrop () {
      const data = this.$refs.cropper.getCropBoxData()
      const width = parseInt(data.width)
      const height = parseInt(data.height)

      this.action.width = width
      this.action.height = height
    },

    onReset () {
      this.$refs.cropper.reset()
      this.onReady()
    },

    onCropEnd () {
      const data = this.$refs.cropper.getCropBoxData()

      const width = parseInt(data.width)
      const height = parseInt(data.height)

      data.width = width
      data.height = height
      this.$refs.cropper.setCropBoxData(data)
    },

    onRotate (rotate) {
      this.action.rotate += rotate
      this.$refs.cropper.rotate(rotate)
    },

    onRotateChange (e) {
      this.action.rotate = e
      this.$refs.cropper.rotateTo(e)
    },

    onZoom (zoom) {
      let pzoom = this.action.zoom
      pzoom += zoom
      pzoom = pzoom.toFixed(1)
      console.log(pzoom)
      this.action.zoom = Number(pzoom)
      this.$refs.cropper.relativeZoom(zoom)
    },

    onZoomChange (e) {
      this.action.zoom = e
      this.$refs.cropper.zoomTo(e)
    },
    onWidthChange (e) {
      const data = this.$refs.cropper.getCropBoxData()
      data.width = e
      this.$refs.cropper.setCropBoxData(data)
    },
    onHeightChange (e) {
      const data = this.$refs.cropper.getCropBoxData()
      data.height = e
      this.$refs.cropper.setCropBoxData(data)
    },

    onMove (x, y) {
      this.$refs.cropper.move(x, y)
    }
  }
})
</script>

<style lang="less" scoped>
.cropper-body {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  .cropper {
    width: 500px;
    .cropper-box {
      width: 500px;
      height: 500px;
      background: url(../assets/drag-bg.png);
    }
  }
  .preview {
    width: 280px;
    height: 547px;
    position: relative;
    .preview-body {
      width: 280px;
      height: 280px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      .preview-box {
        width: 280px;
        height: 280px;
        max-width: 280px;
        max-height: 280px;
        overflow: hidden;
      }
    }

    .actions {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;

      .move {
        margin-top: 15px;
        .hang {
          display: flex;
          justify-content: center;
          button{
            margin: 3px;
          }
        }
      }
      .ac {
        padding-top: 15px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
</style>
