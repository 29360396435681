import request from '@/utils/http'

export class BaseClass {
  // 用户
  user = '/api/User'
  // 账号
  account = '/api/Account'
  // 菜单权限
  menu = '/api/Menu'
  // 角色
  role = '/api/Role'
  store = '/api/Store'
  goods = '/api/Goods'
  goodsCate = '/api/GoodsCategory'
  dish = '/api/Dish'
  dishType = '/api/DishType'
  customer = '/api/Customer'
  combo = '/api/Combo'
  table = '/api/Table'
  plan = '/api/Plan'
  planItem = '/api/PlanItem'
  goodsOut = '/api/GoodsOut'
  goodsIn = '/api/GoodsIn'
  order = '/api/Order'
  orderItem = '/api/OrderItem'
  finAccount = '/api/FinAccount'
  customer = '/api/Customer'
  wxgzh = '/api/WeixinGzh'
  stat = '/api/Stat'
  unionPay = '/api/UnionPay'
  bill = '/api/Bill'
  bookRecord = '/api/BookRecord'
  blackMobile = '/api/BlackMobile'
  config = '/api/Config'
  coupons = '/api/Coupons'
  secondGoods = '/api/SecondGoods'
  recharge = '/api/Recharge'
  supplier = '/api/Supplier'
  sms = '/api/WinicSMS'
  shopcar = '/api/ShoppingCart'

  post (url, data, responseType = 'json', options = {}) {
    return request({
      url, method: 'POST', data, headers: { responseType }, ...options
    })
  }

  put (url, data) {
    return request({
      url, method: 'PUT', data
    })
  }

  patch (url, data) {
    return request({
      url, method: 'PATCH', data
    })
  }

  delete (url, params) {
    return request({
      url, method: 'DELETE', params
    })
  }

  get (url, params) {
    return request({
      url, method: 'GET', params
    })
  }

  upload (url, data) {
    return request({
      url, method: 'post', data, headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
  }

  export (url, method, params) {
    const options = {
      url, method, responseType: 'blob', timeout: 0
    }
    if (method === 'POST') {
      options.data = params
    } else {
      options.params = params
    }
    return request(options)
  }
}
