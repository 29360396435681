<template>
  <a-row :style="{
    'margin-top': margin[0] || 0 + 'px',
    'margin-right': margin[1] || 0 + 'px',
    'margin-bottom': margin[2] || 0 + 'px',
    'margin-left': margin[3] || 0 + 'px',
  }">
    <a-col :span="labelCol" v-if="title">
      <div class="label-title">{{ title }}</div>
    </a-col>
    <a-col :span="title ? valueCol : 24">
      <a-radio-group style="margin-top: 5px" v-model:value="inputValue" @change="onChange">
        <a-radio v-for="(v, k) in options" :key="k" :value="v.value">{{
          v.label
        }}</a-radio>
      </a-radio-group>
    </a-col>
  </a-row>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  props: {
    value: {
      type: Number
    },

    title: {
      type: String,
      default: ''
    },

    labelCol: {
      type: Number,
      default: 4
    },

    valueCol: {
      type: Number,
      default: 16
    },
    options: {
      type: Array,
      default: () => []
    },
    margin: {
      type: Array,
      default: () => [0, 0, 10, 0]
    }
  },
  emits: ['ok', 'update:value'],
  setup (props, { emit }) {
    const inputValue = ref(props.value)

    watch(props, (e) => {
      inputValue.value = e.value
    })

    const onChange = (e) => {
      emit('ok', e.target.value)
      emit('update:value', e.target.value)
    }

    return {
      inputValue,
      onChange
    }
  }
})
</script>
