<template>
  <e-modal
    :visible="visible"
    title="截图上传"
    :width="500"
    :closeAble="false"
    @close="onClose"
    :footer="false"
  >
    <div class="box">
      <div class="paste-box">
        <div class="cover" @paste="onPaste"></div>
        <img v-if="!preview" src="@/assets/paste.png" alt="">
        <img v-else :src="preview" alt="">
      </div>
      <div class="info">
        支持粘贴QQ截图，微信截图，windows截图
      </div>
      <div class="upload-btn" @click="onSubmit">上传</div>
    </div>
  </e-modal>
</template>

<script>
import { defineComponent, ref } from 'vue'
import {} from '@ant-design/icons-vue'

export default defineComponent({
  components: {},
  setup () {
    const imageFile = ref({})
    const visible = ref(false)
    const loading = ref(true)
    const preview = ref('')

    const onClose = () => {
      imageFile.value = {}
      loading.value = false
      visible.value = false
    }

    return {
      imageFile,
      visible,
      loading,
      preview,
      onClose
    }
  },

  methods: {
    open () {
      this.loading = true
      this.visible = true
    },

    onSubmit () {
      this.loading = true
      const formData = new FormData()
      formData.append('file', this.imageFile, this.imageFile.name)
      this.$emit('ok', formData)
    },

    onPaste (e) {
      this.preview = ''
      const items = (e.clipboardData || window.clipboardData).items

      let file = null
      if (!items || items.length === 0) {
        this.$message.error('当前浏览器不支持截图上传')
        return
      }

      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') !== -1) {
          file = items[i].getAsFile()
          break
        }
      }

      if (!file) {
        this.$message.error('粘贴内容非图片')
        return
      }

      const reader = new FileReader()
      reader.onload = event => {
        this.preview = event.target.result
      }
      reader.readAsDataURL(file)
      this.imageFile = file

      console.log(this.imageFile)
    }
  }
})
</script>

<style lang="less" scoped>
.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .paste-box {
    border: 1px dashed #ccc;
    width: 370px;
    height: 220px;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    img{
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
    .cover{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .upload-btn {
    width: 200px;
    height: 35px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    line-height: 35px;
    border-radius: 5px;
    background: rgb(99, 87, 255);
    cursor: pointer;
  }
  .info{
    font-size: 12px;
    line-height: 50px;
    text-align: center;
    color: #ccc;
  }
}
</style>
