import { BaseClass } from './base'

export class ComboClass extends BaseClass {
  modelRef = {
    name: '',
    unitPrice: 0,
    peopleCount: 0,
    price: 0,
    sellingPrice: 0,
    dishItems: []
  }

  rulesRef = {
    name: [
      {
        required: true,
        message: '请输入名称'
      }
    ],
    unitPrice: [{
      required: true,
      message: '请输入餐标'
    }],
    peopleCount: [{
      required: true,
      message: '请输入人数'
    }],
    dishIds: [{
      required: true,
      message: '请设置菜品'
    }]
  }

  gets (params) {
    return this.get(this.combo, params)
  }

  detail (id) {
    return this.get(this.combo + `/${id}`)
  }

  create (data) {
    return this.post(this.combo, data)
  }

  update (data) {
    return this.put(this.combo, data)
  }

  del (id) {
    return this.delete(this.combo + `/${id}`)
  }

  report (params) {
    return this.get(this.combo + '/ComboReport', params)
  }

  exportReport (params) {
    return this.export(this.combo + '/ExportComboReport', 'GET', params)
  }

  copy (params) {
    return this.get(this.combo + '/CopyCombo', params)
  }
}
