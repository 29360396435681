<template>
  <div class="header">
    <div class="bread">
      <div class="menu">
        <menu-unfold-outlined
          v-if="collapsed"
          class="trigger"
          @click="onCollapsed"
        />
        <menu-fold-outlined v-else class="trigger" @click="onCollapsed" />
      </div>

      <home-outlined
        @click="backHome"
        style="color: rgba(0, 0, 0, 0.45); font-size: 14px; margin-right: 2px"
      />
      <a-breadcrumb :routes="routes">
        <template #itemRender="{ route, routes }">
          <span v-if="routes.indexOf(route) === routes.length - 1">
            {{ route.breadcrumbName }}
          </span>
          <router-link v-else :to="route.path">
            {{ route.breadcrumbName }}
          </router-link>
        </template>
      </a-breadcrumb>
    </div>

    <div class="user-info">
      <div class="user-avatar">
        <router-link to="/account/info">
          <img :src="$filters.checkImage(user.avatar)" :alt="user.name" />
        </router-link>
      </div>
      <div class="user-name">
        <router-link to="/account/info">{{ user.name }}</router-link>
      </div>
      <div class="btns">
        <!-- <a-tooltip>
          <template #title>网站首页</template>
          <a href="/" target="_blank"> <HomeOutlined /> &nbsp; 网站首页 </a>
        </a-tooltip> -->

        <a-tooltip>
          <template #title>修改密码</template>
          <a @click.stop="password"> <KeyOutlined /> &nbsp; 修改密码 </a>
        </a-tooltip>

        <a-tooltip>
          <template #title>退出登录</template>
          <a @click.stop="logout"> <LogoutOutlined /> &nbsp; 退出登录 </a>
        </a-tooltip>
      </div>
      <Password
        v-model:visible="visiblePassword"
        @close="closePassword"
        @ok="okPassword"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, createVNode } from 'vue'
import {
  HomeOutlined,
  LogoutOutlined,
  ExclamationCircleOutlined,
  KeyOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined
} from '@ant-design/icons-vue'
import Password from '@/components/forms/Password'
import storage from 'store'
import defaultConfig from '@/config/default.config'

export default defineComponent({
  setup (props, { emit }) {
    const routes = ref([])
    const visiblePassword = ref(false)
    const collapsed = ref(false)

    const onCollapsed = () => {
      collapsed.value = !collapsed.value
      emit('collapsed', collapsed.value)
    }

    return {
      collapsed,
      onCollapsed,
      routes,
      visiblePassword
    }
  },

  components: {
    HomeOutlined,
    LogoutOutlined,
    KeyOutlined,
    Password,
    MenuUnfoldOutlined,
    MenuFoldOutlined
  },

  computed: {
    user: function () {
      return this.$store.state.user.userInfo
    }
  },

  created () {
    // 监听路由变化，更新页面路由信息
    this.$watch(
      () => this.$route,
      () => {
        this.updateRoute()
      },
      { immediate: true }
    )
  },
  methods: {
    updateRoute () {
      const { matched } = this.$route
      const routes = []
      matched.map((e) => {
        routes.push({
          path: e.path,
          breadcrumbName: e.path === '/' ? '首页' : e.meta.title
        })
      })

      this.routes = routes
    },

    backHome () {
      this.$router.push({ name: 'manage' })
    },

    logout () {
      const _this = this
      _this.$confirm({
        content: '确定退出当前用户吗',
        icon: createVNode(ExclamationCircleOutlined),
        okText: '退出',
        onOk () {
          _this.$store.dispatch('Logout').then(() => {
            _this.$message.success('已退出')

            const port = _this.$store.state.user.port || storage.get('PORT')

            if (defaultConfig.roleNames.includes(port)) {
              _this.$router.push({ name: port + '-login' })
            }

            _this.$store.commit('SET_PORT', '')
          })
        }
      })
    },

    password () {
      this.visiblePassword = true
    },
    okPassword (e) {
      if (e) {
        this.visiblePassword = false
        this.$message.success('重置密码成功！')
      }
    },
    closePassword () {
      this.visiblePassword = false
    }
  }
})
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;

  .bread {
    display: flex;
    align-items: center;
    .menu {
      margin-right: 20px;
    }
  }

  .user-info {
    display: flex;
    align-items: center;
    .user-avatar {
      width: 39px;
      height: 39px;
      border-radius: 50%;
      overflow: hidden;
      border: 1px solid #ccc;
      img {
        display: block;
        width: 100%;
      }
    }
    .user-name {
      font-size: 14px;
      padding: 0 5px;
    }

    .btns {
      display: flex;
      align-items: center;
      padding-left: 20px;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 7px;
        font-size: 14px;
        color: #333;
      }
    }
  }
}
</style>
