import storage from 'store'
import defaultConfig from '@/config/default.config'
import store from '@/store'
export function setDocumentTitle (title) {
  document.title = title
  const ua = navigator.userAgent
  // eslint-disable-next-line
  const regex = /\bMicroMessenger\/([\d\.]+)/
  if (regex.test(ua) && /ip(hone|od|ad)/i.test(ua)) {
    const i = document.createElement('iframe')
    i.src = '/favicon.ico'
    i.style.display = 'none'
    i.onload = function () {
      setTimeout(function () {
        i.remove()
      }, 9)
    }
    document.body.appendChild(i)
  }
}

export function dataToPromiseTree (data, parentId) {
  const len = data.length
  const tree = []
  for (let i = 0; i < len; i++) {
    if (data[i].parentId === parentId) {
      const obj = {
        ...data[i]
      }
      obj.actionList = dataToPromiseTree(data, data[i].id)
      tree.push(obj)
    }
  }
  return tree
}

export function dataToTree (data, parentId) {
  const len = data.length
  const tree = []
  for (let i = 0; i < len; i++) {
    if (data[i].parentId === parentId) {
      const obj = {
        ...data[i]
      }
      const children = dataToTree(data, data[i].id)
      if (children.length > 0) {
        obj.children = children
      }

      tree.push(obj)
    }
  }
  return tree
}

/**
 * cascader 数据
 * @param {*} data
 * @param {*} parentId
 * @returns
 */
export function dataToCascader (data, parentId) {
  const len = data.length
  const tree = []
  for (let i = 0; i < len; i++) {
    if (data[i].parentId === parentId) {
      const obj = {
        label: data[i].title,
        value: data[i].id
      }
      const children = dataToCascader(data, data[i].id)
      if (children.length > 0) {
        obj.children = children
      }
      tree.push(obj)
    }
  }
  return tree
}

export function getParentIdArray (data, id, parentField = 'parentId') {
  const arr = []
  const cur = data.filter(x => x.id === id)[0]
  console.log(cur, id)
  arr.push(id)
  if (cur[parentField] !== 0) {
    arr.push(...getParentIdArray(data, cur[parentField]))
  }
  return arr
}

export function createTextArea (text) {
  const isRTL = document.documentElement.getAttribute('dir') === 'rtl'

  const fakeElem = document.createElement('textarea')
  // Prevent zooming on iOS
  fakeElem.style.fontSize = '12pt'
  // Reset box model
  fakeElem.style.border = '0'
  fakeElem.style.padding = '0'
  fakeElem.style.margin = '0'
  // Move element out of screen horizontally
  fakeElem.style.position = 'absolute'
  fakeElem.style[isRTL ? 'right' : 'left'] = '-9999px'
  // Move element to the same position vertically
  const yPosition = window.pageYOffset || document.documentElement.scrollTop
  fakeElem.style.top = `${yPosition}px`

  fakeElem.setAttribute('readonly', '')
  fakeElem.value = text

  return fakeElem
}

export function formatDecimal (num, decimal) {
  num = num.toString()
  const index = num.indexOf('.')
  if (index !== -1) {
    num = num.substring(0, decimal + index + 1)
  } else {
    num = num.substring(0)
  }
  return parseFloat(num).toFixed(decimal)
}

export function setHome () {
  var url = window.location
  var obj = document.body
  try {
    obj.style.behavior = 'url(#default#homepage)'
    obj.setHomePage(url)
  } catch (e) {
    if (window.netscape) {
      try {
        window.netscape.security.PrivilegeManager.enablePrivilege('UniversalXPConnect')
      } catch (e) {
        alert("抱歉，此操作被浏览器拒绝！\n\n请在浏览器地址栏输入“about:config”并回车然后将[signed.applets.codebase_principal_support]设置为'true'")
      }
    } else {
      alert('抱歉，您所使用的浏览器无法完成此操作。\n\n您需要手动将【' + url + '】设置为首页。')
    }
  }
}

export function addCollect () {
  var url = window.location
  var title = document.title
  var ua = navigator.userAgent.toLowerCase()
  if (ua.indexOf('360se') > -1) {
    alert('由于360浏览器功能限制，请按 Ctrl+D 手动收藏！')
  } else if (ua.indexOf('msie 8') > -1) {
    window.external.AddToFavoritesBar(url, title) // IE8
  } else if (document.all) {
    // IE类浏览器
    try {
      window.external.addFavorite(url, title)
    } catch (e) {
      alert('您的浏览器不支持,请按 Ctrl+D 手动收藏!')
    }
  } else if (window.sidebar) {
    // firfox等浏览器；
    window.sidebar.addPanel(title, url, '')
  } else {
    alert('您的浏览器不支持,请按 Ctrl+D 手动收藏!')
  }
}

// 数组去除重复
export function unique (arr) {
  return Array.from(new Set(arr))
}

/* 绑定事件 */
export function addEvent (obj, sType, fn) {
  if (obj.addEventListener) {
    obj.addEventListener(sType, fn, false)
  } else {
    obj.attachEvent('on' + sType, fn)
  }
}
export function addWheelEvent (obj, callback) {
  if (window.navigator.userAgent.toLowerCase().indexOf('firefox') !== -1) {
    addEvent(obj, 'DOMMouseScroll', wheel)
  } else {
    addEvent(obj, 'mousewheel', wheel)
  }
  function wheel (ev) {
    var oEvent = prEvent(ev)
    var delta = oEvent.detail ? oEvent.detail > 0 : oEvent.wheelDelta < 0
    callback && callback.call(oEvent, delta)
    return false
  }
}
export function removeEvent (obj, sType, fn) {
  if (obj.removeEventListener) {
    obj.removeEventListener(sType, fn, false)
  } else {
    obj.detachEvent('on' + sType, fn)
  }
}
export function prEvent (ev) {
  var oEvent = ev || window.event
  if (oEvent.preventDefault) {
    oEvent.preventDefault()
  }
  return oEvent
}

export function hasClass (elem, cls) {
  cls = cls || ''
  if (cls.replace(/\s/g, '').length === 0) return false
  return new RegExp(' ' + cls + ' ').test(' ' + elem.className + ' ')
}

export function addClass (elem, cls) {
  if (!hasClass(elem, cls)) {
    elem.className = elem.className === '' ? cls : elem.className + ' ' + cls
  }
}

export function removeClass (elem, cls) {
  if (hasClass(elem, cls)) {
    var newClass = ' ' + elem.className.replace(/[\t\r\n]/g, '') + ' '
    while (newClass.indexOf(' ' + cls + ' ') >= 0) {
      newClass = newClass.replace(' ' + cls + ' ', ' ')
    }
    elem.className = newClass.replace(/^\s+|\s+$/g, '')
  }
}

export function checkPc (matched) {
  const pc = matched.filter(x => x.name === 'pc')
  if (pc.length > 0) {
    return true
  }
  return false
}

export function checkMerchant (path) {
  return /\/merchant/.test(path)
}

export function checkManage (path) {
  return /\/manage/.test(path)
}

export function checkHall (path) {
  return /\/hall/.test(path)
}

export function checkPath (path, key) {
  return path.indexOf('/' + key) === 0
}

export function getRouteName (name) {
  return name.split('-')[0]
}

export function isLoginRoute (name) {
  return /-login/.test(name)
}

/**
 * 排列组合规格
 */
export function generateGroup (arr) {
  // 初始化结果为第一个数组
  const result = []
  // 字符串形式填充数组
  for (let i = 0; i < arr[0].length; i++) {
    result.push(JSON.stringify(arr[0][i]))
  }
  // 从下标1开始遍历二维数组
  for (let i = 1; i < arr.length; i++) {
    // 使用临时遍历替代结果数组长度(这样做是为了避免下面的循环陷入死循环)
    const size = result.length
    // 根据结果数组的长度进行循环次数,这个数组有多少个成员就要和下一个数组进行组合多少次
    for (let j = 0; j < size; j++) {
      // 遍历要进行组合的数组
      for (let k = 0; k < arr[i].length; k++) {
        // 把组合的字符串放入到结果数组最后一个成员中
        // 这里使用下标0是因为当这个下标0组合完毕之后就没有用了，在下面我们要移除掉这个成员
        // 组合下一个json字符串
        const temp = result[0] + ',' + JSON.stringify(arr[i][k])
        result.push(temp)
      }
      // 当第一个成员组合完毕，删除这第一个成员
      result.shift()
    }
  }
  // 转换字符串为json对象
  for (let i = 0; i < result.length; i++) {
    result[i] = JSON.parse('[' + result[i] + ']')
  }
  return result
}

export function windowInit () {
  var lastTime = 0
  var vendors = ['webkit', 'moz']
  for (var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
    window.requestAnimationFrame = window[vendors[x] + 'RequestAnimationFrame']
    window.cancelAnimationFrame = window[vendors[x] + 'CancelAnimationFrame'] || // name has changed in Webkit
      window[vendors[x] + 'CancelRequestAnimationFrame']
  }

  if (!window.requestAnimationFrame) {
    window.requestAnimationFrame = function (callback, element) {
      var currTime = new Date().getTime()
      var timeToCall = Math.max(0, 16.7 - (currTime - lastTime))
      var interval = currTime - lastTime
      var id = window.setTimeout(function () {
        callback(interval)
      }, timeToCall)
      lastTime = currTime + timeToCall
      return id
    }
  }
  if (!window.cancelAnimationFrame) {
    window.cancelAnimationFrame = function (id) {
      clearTimeout(id)
    }
  }
}

export function isIos () {
  const isIphone = navigator.userAgent.includes('iPhone')
  const isIpad = navigator.userAgent.includes('iPad')
  if (isIphone || isIpad) {
    return true
  }
  return false
}

export function iosLoginOrRegist (path) {
  if (isIos()) {
    const url = defaultConfig.host + path
    storage.set('wxConfigURL', url)
    wechatConfig(url)
  }
}

export function wechatConfig (url = '') {
  store.dispatch('WxConfig', url).then((resp) => {
    // eslint-disable-next-line no-undef
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: resp.appId, // 必填，公众号的唯一标识
      timestamp: resp.timestamp, // 必填，生成签名的时间戳
      nonceStr: resp.nonceStr, // 必填，生成签名的随机串
      signature: resp.signature.toLowerCase(), // 必填，签名
      jsApiList: ['openLocation', 'getLocation'] // 必填，需要使用的JS接口列表
    })
  })
}
