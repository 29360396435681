<template>
  <div class="counter-navs">
    <ul>
      <li v-for="(v, k) in list" :key="k">
        <router-link v-if="!v.hide" :to="v.path">
          <div class="icon">
            <icon-font :type="v.meta.icon" />
          </div>
          <div class="title">{{ v.meta.title }}</div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import store from '@/store'

export default defineComponent({
  setup () {
    const loading = ref(false)

    return {
      loading,
      list: store.state.permission.addRouters[0].children
    }
  },

  created () {

  }
})
</script>

<style lang="less" scoped>
.counter-navs {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0px;
    height: 6px;
  }

  /* 滚动槽 */
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  /* 滚动条滑块 */
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #998675;
  }
  ul {
    list-style: none;
    padding: 0;

    li {
      padding-bottom: 0.2rem;
      a {
        display: flex;
        align-items: center;
        height: 0.56rem;
        color: #333;
        padding-left: 0.32rem;
        .icon {
          width: 0.45rem;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .title,
        .icon {
          position: relative;
          z-index: 2;
        }
        &.router-link-active {
          position: relative;
          color: #998675;
          &:after {
            position: absolute;
            top: 0;
            left: 0;
            content: "";
            display: block;
            width: 0.04rem;
            height: 100%;
            background: #998675;
          }
          &:before {
            position: absolute;
            top: 0;
            right: 0;
            content: "";
            display: block;
            width: 2.43rem;
            height: 100%;
            background: #f5f3f1;
            border-radius: 0 0.56rem 0 0;
          }
        }
      }
    }
  }
}
</style>
