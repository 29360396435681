<template>
  <div class="user-container">
    <div class="header">
      <h1>
        <router-link to="/" style="color: #333">
          <img src="@/assets/logo.png" alt="" />{{ title }}
        </router-link>
      </h1>
      <h2>会员登录</h2>
    </div>
    <div class="body">
      <router-view />
    </div>
    <div class="footer">
      <div class="device-width">
        <p>版权所有：{{ company }}</p>
        <p>版本：{{ version }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import defaultConfig from '@/config/default.config'
export default defineComponent({
  setup () {
    const { title, version, company } = defaultConfig
    return {
      title,
      version,
      company
    }
  }
})
</script>

<style lang="less" scoped>
.user-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 80px;
  padding-bottom: 60px;
  box-sizing: border-box;
  background-image: linear-gradient(45deg, #341b55, #aa5c58);
  .header {
    position: absolute;
    top: 0;
    left: 0;
    height: 80px;
    width: 100%;
    display: flex;
    line-height: 80px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(255, 255, 255, 0.7);
    h1 {
      font-size: 26px;
      display: flex;
      align-items: center;
      padding-left: 30px;
      img {
        height: 50px;
        margin-right: 10px;
      }
    }
    h2 {
      font-size: 20px;
      margin-left: 30px;
    }
  }
  .body {
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, #211136, #bf5853);
  }
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    text-align: center;
    color: #ccc;
    .device-width {
      width: 1200px;
      margin: 0 auto;
      border-top: 1px solid #ccc;
      padding-top: 10px;
      p {
        font-size: 12px;
        line-height: 24px;
        padding: 0;
        margin: 0;
      }
    }
  }
}
</style>
