import { createFromIconfontCN } from '@ant-design/icons-vue'

const IconFont = createFromIconfontCN({
  scriptUrl: '/icon-font.js'
})

export default {
  name: 'icon-font',
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  render () {
    return (
      <IconFont type={this.type} />
    )
  }
}
