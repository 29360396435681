import { BaseClass } from './base'

export class DishClass extends BaseClass {
  modelRef = {
    name: '',
    dishTypeId: '',
    pic: '',
    unit: '',
    tasteType: '',
    label: '',
    brief: '',
    price: 0,
    type: 0,
    sellingPrice: 0,
    grossMargin: 0,
    recommendedSortNum: 0,
    isOn: true,
    isMust: false,
    isPersonal: false,
    dishGoodsItems: []
  }

  rulesRef = {
    name: [
      {
        required: true,
        message: '请输入名称'
      }
    ],
    dishTypeId: [
      {
        required: true,
        message: '请输入菜品分类'
      }
    ]
  }

  gets (params) {
    return this.get(this.dish, params)
  }

  tempDishes () {
    return this.get(this.dish + '/TempDishes')
  }

  detail (id) {
    return this.get(this.dish + `/${id}`)
  }

  create (data) {
    return this.post(this.dish, data)
  }

  update (data) {
    return this.put(this.dish, data)
  }

  del (id) {
    return this.delete(this.dish + `/${id}`)
  }

  state (id, state) {
    return this.get(this.dish + `/${id}/${state}`)
  }

  setMust (id, state) {
    return this.get(this.dish + `/SetMust/${id}/${state}`)
  }

  setPersonal (id, state) {
    return this.get(this.dish + `/SetPersonal/${id}/${state}`)
  }

  setPrice (data) {
    return this.get(this.dish + '/ChangePrice', data)
  }

  getList (ids) {
    return this.get(this.dish + '/GetListByIds', { ids })
  }

  report (params) {
    return this.get(this.dish + '/DishReport', params)
  }

  reportSum (params) {
    return this.get(this.dish + '/DishSumReport', params)
  }

  exportReport (params) {
    return this.export(this.dish + '/ExportDishReport', 'GET', params)
  }

  exportSumReport (params) {
    return this.export(this.dish + '/ExportDishSumReport', 'GET', params)
  }

  dishGoodsReport (params) {
    return this.get(this.dish + '/DishGoodsReport', params)
  }

  exportDishGoodsReport (params) {
    return this.export(this.dish + '/ExportDishGoodsReport', 'GET', params)
  }
}

export class DishTypeClass extends BaseClass {
  modelRef = {
    title: '',
    parentId: 0,
    sort: 0,
    printSN: '',
    printCount: 1
  }

  rulesRef = {
    title: [
      {
        required: true,
        message: '请输入标题'
      }
    ]
  }

  gets (params) {
    return this.get(this.dishType, params)
  }

  detail (id) {
    return this.get(this.dishType + `/${id}`)
  }

  create (data) {
    return this.post(this.dishType, data)
  }

  update (data) {
    return this.put(this.dishType, data)
  }

  del (id) {
    return this.delete(this.dishType + `/${id}`)
  }

  sort (id, sort) {
    return this.get(this.dishType + `/${id}/${sort}`)
  }
}

export class ShoppingCartClass extends BaseClass {
  create (data) {
    return this.post(this.shopcar, data)
  }

  clear () {
    return this.delete(this.shopcar)
  }

  gets () {
    return this.get(this.shopcar)
  }
}
