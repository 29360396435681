import { BaseClass } from './base'

export class PlanClass extends BaseClass {
  gets (params) {
    return this.get(this.plan, params)
  }

  detail (id) {
    return this.get(this.plan + `/${id}`)
  }

  state (id, state) {
    return this.put(this.plan + '/ChangeState', { id, state })
  }

  stat () {
    return this.get(this.plan + '/YesterdayStat')
  }

  monthStat () {
    return this.get(this.plan + '/PlanMonthStat')
  }

  canteenMonthStat () {
    return this.get(this.plan + '/CanteenMonthStat')
  }

  getItems (id) {
    return this.get(this.plan + `/GetUserPlanItemsByPlanId/${id}`)
  }
}

export class PlanItemClass extends BaseClass {
  create (data) {
    return this.post(this.planItem, data)
  }

  createOne (data) {
    return this.post(this.planItem + '/AddSingle', data)
  }

  edit (data) {
    return this.put(this.planItem, data)
  }

  del (id) {
    return this.delete(this.planItem + `/${id}`)
  }

  delGoods (goodsId) {
    return this.delete(this.planItem + `/DelGoods/${goodsId}`)
  }

  gets (params) {
    return this.get(this.planItem, params)
  }

  out (data) {
    return this.post(this.planItem + '/DirectGoodsOut', data)
  }

  getItems (params) {
    return this.get(this.planItem + '/GetsByPlanId', params)
  }

  setPrice (planId, goodsId, unitPrice, supplierId = 0) {
    return this.post(this.planItem + '/SetPrice', { planId, goodsId, unitPrice, supplierId })
  }

  cateStat (params) {
    return this.get(this.planItem + '/CategoryStat', params)
  }

  exportCateStat (params) {
    return this.export(this.planItem + '/ExportCategoryStat', 'GET', params)
  }

  percentStat (params) {
    return this.get(this.planItem + '/PercentStat', params)
  }

  exportPercentStat (params) {
    return this.export(this.planItem + '/ExportPercentStat', 'GET', params)
  }

  goodsStat (params) {
    return this.get(this.planItem + '/GoodsStat', params)
  }

  exportGoodsStat (params) {
    return this.export(this.planItem + '/ExportGoodsStat', 'GET', params)
  }

  exportGets (params) {
    return this.export(this.planItem + '/ExportGets', 'GET', params)
  }

  exportStoreGoods (planId) {
    return this.export(this.planItem + '/ExportGoodsStatByCanteen', 'GET', { planId })
  }

  exportPlanItems (params) {
    return this.export(this.planItem + '/ExportByPlanId', 'GET', params)
  }
}
