<template>
  <a-row>
    <a-col :span="labelCol">
      <div class="label-title">{{ title }}</div>
    </a-col>
    <a-col :span="valueCol">
      <a-form-item v-bind="validate">
        <a-input v-model:value="inputValue" @change="onChange" allowClear>
          <template #addonAfter>
            <a-tooltip>
              <template #title>选择图片</template>
              <a @click.stop="$refs.resource.open(1, '')">
                <FileSearchOutlined />
              </a>
            </a-tooltip>
          </template>
        </a-input>
        <span v-if="validate" class="must">*</span>
      </a-form-item>
    </a-col>
  </a-row>
  <Resource ref="resource" @ok="emitChooseResource" />
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
import { FileSearchOutlined } from '@ant-design/icons-vue'
import Resource from '@/components/Resource'

export default defineComponent({
  props: {
    value: {
      type: String
    },

    title: {
      type: String,
      default: '标题'
    },

    labelCol: {
      type: Number,
      default: 4
    },

    valueCol: {
      type: Number,
      default: 16
    },

    validate: {
      type: Object,
      default: () => null
    }
  },
  components: {
    FileSearchOutlined,
    Resource
  },
  setup (props, { emit }) {
    const inputValue = ref(props.value)

    watch(props, (e) => {
      console.log(props.validate)
      inputValue.value = e.value
    })

    const onChange = (e) => {
      emit('update:value', e.target.value)
    }

    const emitChooseResource = (e) => {
      const image = e.images[0]
      emit('update:value', image)
    }

    return {
      inputValue,
      onChange,
      emitChooseResource
    }
  }
})
</script>

<style lang="less" scoped>
.must {
  position: absolute;
  top: 0;
  right: -10px;
  color: #f00;
  font-size: 18px;
  line-height: 32px;
}
</style>
