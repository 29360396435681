<template>
  <div class="mobile-content">
    <router-view></router-view>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  setup () {
    return {}
  },
  created () {
    console.log(document.documentElement.clientWidth / 7.5 + 'px')
    document.documentElement.style.fontSize = document.documentElement.clientWidth / 7.5 + 'px'
  },
  methods: {}
})
</script>

<style lang="less" scoped>
.mobile-content {
  background: #f5f5f5;
  min-height: 100vh;
}
</style>
