import { BaseClass } from './base'

export class GoodsClass extends BaseClass {
  modelRef = {
    title: '',
    pic: '',
    stockPrice: 0,
    sellingPrice: 0,
    promotionPrice: 0,
    quantity: 0,
    unit: '',
    specification: '',
    brand: '',
    goodsCategoryId: '',
    transferUnit: '',
    transferNum: 0
  }

  rulesRef = {
    title: [
      {
        required: true,
        message: '请输入名称'
      }
    ],
    goodsCategoryId: [
      {
        required: true,
        message: '请选择商品分类'
      }
    ]
  }

  gets (params) {
    return this.get(this.goods, params)
  }

  detail (id) {
    return this.get(this.goods + `/${id}`)
  }

  create (data) {
    return this.post(this.goods, data)
  }

  update (data) {
    return this.put(this.goods, data)
  }

  del (id) {
    return this.delete(this.goods + `/${id}`)
  }

  toDish (id, isMust, dishType, sellingPrice) {
    return this.put(this.goods + '/AddToDish', { id, isMust, dishType, sellingPrice })
  }

  getList (ids) {
    return this.get(this.goods + '/GetListByIds', { ids })
  }

  out (data) {
    return this.post(this.goods + '/GoodsOutToPeople', data)
  }

  exportGoods (params) {
    return this.export(this.goods + '/Exports', 'GET', params)
  }

  clear () {
    return this.get(this.goods + '/ClearQuantity')
  }
}

/**
 * 商品分类
 */
export class GoodsCategoryClass extends BaseClass {
  modelRef = {
    title: '',
    parentId: 0,
    sort: 0
  }

  rulesRef = {
    title: [
      {
        required: true,
        message: '请输入标题'
      }
    ]
  }

  gets (params) {
    return this.get(this.goodsCate, params)
  }

  detail (id) {
    return this.get(this.goodsCate + `/${id}`)
  }

  create (data) {
    return this.post(this.goodsCate, data)
  }

  update (data) {
    return this.put(this.goodsCate, data)
  }

  del (id) {
    return this.delete(this.goodsCate + `/${id}`)
  }
}

/**
 * 出库
 */
export class GoodsOutClass extends BaseClass {
  gets (params) {
    return this.get(this.goodsOut, params)
  }

  detail (id) {
    return this.get(this.goodsOut + `/${id}`)
  }

  create (data) {
    return this.post(this.goodsOut, data)
  }

  del (id) {
    return this.delete(this.goodsOut + `/${id}`)
  }

  check (data) {
    return this.put(this.goodsOut, data)
  }

  report (params) {
    return this.get(this.goodsOut + '/GoodsOutMoneyReport', params)
  }

  exportStat (params) {
    return this.export(this.goodsOut + '/ExportGoodsOutMoneyReport', 'GET', params)
  }

  exportExcel (params) {
    return this.export(this.goodsOut + '/Exports', 'GET', params)
  }
}

/**
 * 入库
 */
export class GoodsInClass extends BaseClass {
  gets (params) {
    return this.get(this.goodsIn, params)
  }

  detail (id) {
    return this.get(this.goodsIn + `/${id}`)
  }

  create (data) {
    return this.post(this.goodsIn, data)
  }

  del (id) {
    return this.delete(this.goodsIn + `/${id}`)
  }

  report (params) {
    return this.get(this.goodsIn + '/GoodsInMoneyReport', params)
  }

  exportStat (params) {
    return this.export(this.goodsIn + '/ExportGoodsInMoneyReport', 'GET', params)
  }

  supplierStat (params) {
    return this.get(this.goodsIn + '/SupplierSettlementStat', params)
  }

  exportExcel (params) {
    return this.export(this.goodsIn + '/Exports', 'GET', params)
  }
}

export class SecondGoodsClass extends BaseClass {
  gets (params) {
    return this.get(this.secondGoods, params)
  }

  quantity (data) {
    return this.put(this.secondGoods, data)
  }

  exportExcel (params) {
    return this.export(this.secondGoods + '/Exports', 'GET', params)
  }
}
