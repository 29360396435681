<template>
  <a-row class="search-params-margin">
    <a-col :span="labelCol">
      <div class="label-title" v-if="showTitle || title">{{title}}</div>
    </a-col>
    <a-col :span="valueCol">
      <a-input v-model:value="inputValue" @change="onChange" allowClear :disabled="disabled" :type="type" :placeholder="'请输入' + title" />
    </a-col>
  </a-row>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  props: {
    value: {
      type: String
    },

    title: {
      type: String,
      default: '标题'
    },

    labelCol: {
      type: Number,
      default: 4
    },

    valueCol: {
      type: Number,
      default: 16
    },

    disabled: {
      type: Boolean,
      default: false
    },

    type: {
      type: String,
      default: 'text'
    },
    showTitle: {
      type: Boolean,
      default: true
    }
  },
  emits: ['ok', 'update:value'],
  setup (props, { emit }) {
    const inputValue = ref(props.value)

    watch(props, e => {
      inputValue.value = e.value
    })

    const onChange = e => {
      emit('ok', e.target.value)
      emit('update:value', e.target.value)
    }

    return {
      inputValue,
      onChange
    }
  }
})
</script>
