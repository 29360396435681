<template>
  <a-row>
    <a-col :span="labelCol">
      <div class="label-title" v-if="title">{{ title }}</div>
    </a-col>
    <a-col :span="valueCol">
      <a-form-item :help="help">
        <a-switch
          v-model:checked="inputValue"
          @change="onChange"
          checked-children="是"
          un-checked-children="否"
        />
      </a-form-item>
    </a-col>
  </a-row>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  props: {
    value: {
      type: Boolean,
      default: false
    },

    title: {
      type: String,
      default: '标题'
    },

    help: {
      type: String,
      default: ''
    },

    labelCol: {
      type: Number,
      default: 4
    },

    valueCol: {
      type: Number,
      default: 16
    }
  },
  emits: ['ok', 'update:value'],
  setup (props, { emit }) {
    const inputValue = ref(props.value)

    watch(props, (e) => {
      inputValue.value = e.value
    })

    const onChange = (e) => {
      emit('ok', e)
      emit('update:value', e)
    }

    return {
      inputValue,
      onChange
    }
  }
})
</script>

<style lang="less" scoped>
.must {
  position: absolute;
  top: 0;
  right: -10px;
  color: #f00;
  font-size: 18px;
  line-height: 32px;
}
</style>
