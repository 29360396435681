
const app = {
  state: {
    api_host: '',
    host: '',
    version: '',
    bookDate: '',
    unit: [
      {
        label: '公斤',
        value: '1000'
      },
      {
        label: '斤',
        value: '500'
      },
      {
        label: '克',
        value: '1'
      }
    ],

    sexes: [
      {
        label: '未知',
        value: 0
      },
      {
        label: '男',
        value: 1
      },
      {
        label: '女',
        value: 2
      }
    ],
    audios: [
      {
        label: '未审核',
        value: 0
      },
      {
        label: '审核通过',
        value: 1
      },
      {
        label: '审核失败',
        value: 2
      }
    ],

    table: [
      {
        label: '空房',
        value: 0
      },
      {
        label: '预定',
        value: 1
      },
      {
        label: '开餐',
        value: 2
      }
    ],

    payChannel: [
      '未付款',
      '全民付',
      '扫码支付',
      '会员支付',
      '刷卡支付',
      '现金支付',
      '支付宝扫码',
      '微信扫码',
      '挂账',
      '齐商银行',
      '会员卡',
      '连台结算',
      '美团团购',
      '抖音团购',
      '美团外卖',
      '饿了么外卖'
    ],

    is_customer_login: false
  },
  mutations: {
    SET_API_HOST (state, value) {
      state.api_host = value
    },
    SET_HOST (state, value) {
      state.host = value
    },
    SET_VERSION (state, value) {
      state.version = value
    },
    SET_IS_CUSTOMER_LOGIN (state, value) {
      state.is_customer_login = value
    },
    SET_BOOKDATE (state, value) {
      state.bookDate = value
    }
  },
  actions: {}
}

export default app
