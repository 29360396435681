import { BaseClass } from './base'

export class StoreClass extends BaseClass {
  modelRef = {
    title: '',
    logo: '',
    logoAcross: '',
    lng: '',
    lat: '',
    phone: '',
    address: '',
    description: ''
  }

  rulesRef = {
    title: [
      {
        required: true,
        message: '请输入名称'
      }
    ]
  }

  gets (params) {
    return this.get(this.store, params)
  }

  detail (id) {
    return this.get(this.store + `/${id}`)
  }

  create (data) {
    return this.post(this.store, data)
  }

  update (data) {
    return this.put(this.store, data)
  }

  del (id) {
    return this.delete(this.store + `/${id}`)
  }
}
