<template>
  <a-config-provider :locale="locale">
    <router-view />
  </a-config-provider>
</template>

<script>

import zhCN from 'ant-design-vue/es/locale/zh_CN'
import { defineComponent } from 'vue'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
dayjs.locale('zh-cn')

export default defineComponent({
  setup () {
    return {
      locale: zhCN
    }
  }
})
</script>

<style lang="less">
@import "./base_pc_style.less";
.content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 9px;
  position: absolute;
  top: 0;
  left: 0;
  .main-content {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    padding: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 10px;

    .search-form {
      border: 1px solid #b1d8fc;
      border-radius: 5px;
      background: #e6fffb;
      padding: 15px;
    }
    .form-body {
      padding-bottom: 55px;
    }

    .btn-view {
      position: absolute;
      bottom: 9px;
      left: 0;
      border-top: 1px solid #d1d2d3;
      width: 100%;
      padding: 10px 15px;
      background: #f3f3f3;
    }
  }
  .pagination {
    display: none;
    &.on{
      display: flex;
      align-items: flex-end;
    }
  }
  &.page {
    padding-bottom: 50px;
    .pagination {
      padding-top: 9px;
      display: flex;
      align-items: flex-end;
    }
  }
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  padding: 0 10px;
}
.ant-table-body {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  /* 滚动槽 */
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  /* 滚动条滑块 */
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #1890ff;
  }
}

.ant-table-fixed-header .ant-table-body-inner {
  overflow: auto !important;
}
.ant-table-fixed-header
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-body {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #1890ff;
  }
}

.ant-form-horizontal .ant-form-item-label {
  border: 1px solid #ccc;
  border-right: none;
  box-sizing: border-box;
  height: 32px;
  background: #b1d8fc;
  border-radius: 2px 0 0 2px;
  color: #fff;
}

.overflow-scroll {
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 6px;
  }

  /* 滚动槽 */
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  /* 滚动条滑块 */
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #1890ff;
  }
}

.dropdown-body {
  background: #fff;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  .part {
    border-bottom: 1px solid rgb(236, 236, 236);
    .title{
      color: #999;
      font-size: 12px;
      line-height: 30px;
    }
    .body{
      padding: 10px 0;
    }
  }

  .btns{
    display: flex;
    padding: 10px 0;
    .btn{
      font-size: 12px;
      border: 1px solid #ccc;
      margin-right: 10px;
      padding: 3px 15px;
      border-radius: 5px;
      background: #fff;
      &.reset{
        border-color: #ff4d4f;
        color: #ff4d4f;
      }
      &.sure{
        color: #1890ff;
        border-color: #1890ff;
      }
    }
  }
}

.ant-modal-mask,
.ant-message,
.ant-notification,
.ant-cascader-menus,
.ant-picker-dropdown {
  z-index: 99997 !important;
}
.ant-modal-wrap {
  z-index: 99998 !important;
}
.ant-select-dropdown {
  z-index: 100001 !important;
}

.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link{
  padding: 0 15px !important;
}

.label-title {
  position: relative;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  text-align: right;

  &:after {
    content: ":";
    position: relative;
    top: -0.5px;
    margin: 0 8px 0 2px;
  }
}
.username {
  display: flex;
  align-items: center;
  img {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #ccc;
  }
  span {
    margin-left: 5px;
    &.sex {
      font-size: 12px;
      color: #ccc;
      &.man {
        color: rgb(66, 48, 228);
      }
      &.feman {
        color: rgb(228, 48, 48);
      }
    }
  }
}

.ant-form-item-has-error .ant-input-number,
.ant-form-item-has-error .ant-picker {
  border-color: #ff4d4f !important;
}
</style>
