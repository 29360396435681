import { BaseClass } from './base'

export class SupplierClass extends BaseClass {
  modelRef = {
    name: '',
    mobile: '',
    remark: ''
  }

  rulesRef = {
    name: [
      {
        required: true,
        message: '请输入姓名'
      }
    ],
    mobile: [
      {
        required: true,
        message: '请输入手机号'
      }
    ]
  }

  gets (params) {
    return this.get(this.supplier, params)
  }

  create (data) {
    return this.post(this.supplier, data)
  }

  update (data) {
    return this.put(this.supplier, data)
  }

  del (id) {
    return this.delete(this.supplier + `/${id}`)
  }
}
