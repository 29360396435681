<template>
  <div class="header">
    <div class="header-content">
      <a-tooltip>
        <template #title>二级库</template>
        <a @click.stop="$router.push({ name: 'counter-store' })" class="icon">
          <AppstoreOutlined />
        </a>
      </a-tooltip>

      <a-tooltip>
        <template #title>交接班打印</template>
        <a @click.stop="onShiftPrint" class="icon">
          <PrinterOutlined />
        </a>
      </a-tooltip>

      <div class="avatar">
        <img :src="$filters.image(user.avatar)" :alt="user.name" />
      </div>

      <span>你好，{{ user.name }}！</span>

      <a
        :class="{
          icon: true,
          small: true,
          on: showDrop,
        }"
        @click="dropDown"
      >
        <DownOutlined />
      </a>

      <div class="drop-down" v-if="showDrop">
        <div
          :class="{
            'drop-down-body': true,
            on: showDropBody,
          }"
        >
          <a class="item" @click="password">
            <KeyOutlined />
            修改密码
          </a>
          <a class="item" @click="logout">
            <PoweroffOutlined />
            退出登录
          </a>
        </div>
      </div>
    </div>

    <Password
      v-model:visible="visiblePassword"
      @close="closePassword"
      @ok="okPassword"
    />
  </div>
</template>

<script>
import { defineComponent, ref, createVNode } from 'vue'
import {
  AppstoreOutlined,
  PrinterOutlined,
  DownOutlined,
  PoweroffOutlined,
  KeyOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons-vue'
import Password from '@/components/forms/Password'
import { StatClass } from '@/apis/stat'
import { message } from 'ant-design-vue'
import store from '@/store'

const stat = new StatClass()
export default defineComponent({
  components: {
    AppstoreOutlined,
    PrinterOutlined,
    DownOutlined,
    PoweroffOutlined,
    KeyOutlined,
    Password
  },
  setup () {
    const loading = ref(false)
    const user = ref({})
    const showDrop = ref(false)
    const showDropBody = ref(false)
    const visiblePassword = ref(false)

    const onShiftPrint = () => {
      const bookDate = store.state.app.bookDate
      stat.shift(bookDate).then(() => {
        message.success('交接班打印成功')
      })
    }

    return {
      loading,
      user,
      showDrop,
      showDropBody,
      visiblePassword,
      onShiftPrint
    }
  },

  created () {
    this.user = this.$store.state.user.userInfo

    this.$watch('showDrop', (e) => {
      setTimeout(() => {
        this.showDropBody = e
      }, 10)
    })
  },

  methods: {
    dropDown () {
      this.showDrop = !this.showDrop
    },

    logout () {
      const _this = this
      _this.$confirm({
        content: '确定退出当前用户吗',
        icon: createVNode(ExclamationCircleOutlined),
        okText: '退出',
        onOk () {
          _this.$store.dispatch('Logout').then(() => {
            _this.$message.success('已退出')
            _this.$router.push({ name: 'counter-login' })
          })
        }
      })
    },

    password () {
      this.visiblePassword = true
    },
    okPassword (e) {
      if (e) {
        this.visiblePassword = false
        this.$message.success('重置密码成功！')
      }
    },
    closePassword () {
      this.visiblePassword = false
    }
  }
})
</script>

<style lang="less" scoped>
.header {
  position: absolute;
  top: 0;
  right: 0;
  width: 5.88rem;
  height: 0.7rem;
  z-index: 9999;
  .header-content {
    padding: 0.15rem 0.7rem 0 0;
    height: 0.4rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #333333;
    font-size: 14px;
    position: relative;
    .icon {
      color: #333333;
      font-size: 0.2rem;
      margin-left: 0.4rem;
      &.small {
        font-size: 0.16rem;
        transition: all 0.2s ease;
        &.on {
          transform: rotate(-180deg);
        }
      }

      &:hover {
        color: #1890ff;
      }
    }
    .avatar {
      width: 0.4rem;
      height: 0.4rem;
      margin-left: 0.4rem;
      margin-right: 0.1rem;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        display: block;
        max-width: 100%;
        max-height: 100%;
      }
    }

    .drop-down {
      position: absolute;
      top: 0.5rem;
      right: 0.7rem;
      z-index: 9;
      .drop-down-body {
        position: absolute;
        top: 100px;
        right: 0;
        width: 120px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(200, 200, 200, 0.1);
        padding: 0.15rem;
        transition: all 0.3s ease;
        opacity: 0;
        .item {
          display: block;
          color: #333;
          font-size: 14px;
          line-height: 50px;
          height: 50px;
          span {
            margin-right: 5px;
          }

          &:hover {
            color: #1890ff;
          }
        }

        &.on {
          top: 0;
          opacity: 1;
        }
      }
    }
  }
}
</style>
