<template>
  <e-modal
    :visible="visible"
    title="修改密码"
    :width="400"
    @close="handleClose"
    @ok="handleOk"
  >
    <a-spin tip="Loading..." :spinning="loading">
      <a-form v-bind="layout">

        <e-input type="password" :labelCol="8" :valueCol="16" title="新密码" :validate="validateInfos.password" v-model:value="modelRef.password" />
        <e-input type="password" :labelCol="8" :valueCol="16" title="确认密码" :validate="validateInfos.passwordConfirm" v-model:value="modelRef.passwordConfirm" />

      </a-form>
    </a-spin>
  </e-modal>
</template>
<script>
import { defineComponent, toRefs, reactive, toRaw, ref } from 'vue'
import { Form } from 'ant-design-vue'
// import { LockOutlined } from '@ant-design/icons-vue'
import form from '@/mixins/form'
import { UserClass } from '@/apis/user'

const useForm = Form.useForm
const api = new UserClass()
export default defineComponent({
  mixins: [form],
  components: {
    // LockOutlined
  },
  emits: ['close', 'ok'],
  props: {
    userId: {
      type: String,
      default: ''
    }
  },
  setup (props, { emit }) {
    const { visible, userId } = toRefs(props)
    const loading = ref(false)

    const modelRef = reactive({
      passwordConfirm: '',
      password: ''
    })

    const validatePass = async (rule, value) => {
      if (value === '') {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('请确认密码')
      } else {
        if (value !== modelRef.password) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject('两次密码不一致')
        }

        return Promise.resolve()
      }
    }

    const rulesRef = reactive({
      passwordConfirm: [
        {
          required: true,
          validator: validatePass
        }
      ],
      password: [
        {
          required: true,
          message: '请输入密码'
        }
      ]
    })

    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef
    )

    const handleClose = () => {
      resetFields()
      emit('close', true)
    }

    const handleOk = () => {
      validate()
        .then(() => {
          const data = { ...toRaw(modelRef) }

          if (userId.value) {
            data.userId = userId.value
          }

          api.psw(data).then(() => {
            resetFields()
            emit('ok', true)
          })
        })
        .catch(() => {
          emit('ok', false)
        })
    }

    return {
      loading,
      visible,
      modelRef,
      rulesRef,
      handleClose,
      handleOk,
      validateInfos,
      resetFields,
      validate
    }
  },

  created () {
    this.$watch('visible', (newVal, oldVal) => {
      console.log(newVal, oldVal)
    })
  }
})
</script>

<style lang="less" scoped></style>
