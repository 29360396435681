import { BaseClass } from './base'

export class StatClass extends BaseClass {
  info () {
    return this.get(this.stat + '/StoreDashboardStat')
  }

  line () {
    return this.get(this.stat + '/OrderMoneyStat')
  }

  shift (bookDate) {
    return this.get(this.stat + '/ShiftPrint', { bookDate })
  }
}
